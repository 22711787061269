// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { clearStorage } from "./clearstore";

// Set the session data with an expiration time
const setSession = (key, value, expires) => {
  const expiration = expires;
  // new Date(Date.now() + expireMinutes * 60 * 1000);
  // sessionStorage.setItem(key, JSON.stringify({ value, expiration }));\
  localStorage.setItem(key, JSON.stringify({ value, expiration }));
};

// Get the session data if it's not expired
const getSession = (key) => {
  // const data = JSON.parse(sessionStorage.getItem(key));
  const data = JSON.parse(localStorage.getItem(key));
  if (!data || new Date(data.expiration) < new Date()) {
    if (key === "session_hwai") clearStorage();
    // sessionStorage.removeItem(key);
    localStorage.removeItem(key);
    return null;
  }
  return data.value;
};

const cleanSession = (key) => {
  // sessionStorage.removeItem(key);
  localStorage.removeItem(key);
  return null;
};

const getSessionWithExpiration = (key) => {
  // const data = JSON.parse(sessionStorage.getItem(key));
  const data = JSON.parse(localStorage.getItem(key));
};
export { setSession, getSession, cleanSession };
