// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useEffect, useRef, useState } from "react";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";

const TicketDropDown = ({
  check,
  options,
  setCheck,
  h = 7,
  bcol = "border-gray-300",
  showwarning,
  setShowWarning,
}) => {
  const [displayChecked, setDisplayChecked] = useState("-Select-");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [isSecondaryHovered, setSecondaryHovered] = useState(false);
  const [secondaryDropdownStyle, setSecondaryDropdownStyle] = useState({});
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setDisplayChecked(check || "-Select-");
  }, [check]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
        setHoveredOption(null);
        setSecondaryHovered(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleOptionClick = (dashboardname) => {
    setCheck(dashboardname);
    setDisplayChecked(dashboardname);
    setShowWarning(false);
    setHoveredOption(null);
    setDropdownOpen(false);
  };

  const handlePrimaryMouseLeave = () => {
    if (!isSecondaryHovered) {
      const timeout = setTimeout(() => {
        setHoveredOption(null);
      }, 10000);
      setHoverTimeout(timeout);
    }
  };

  const handleSecondaryMouseEnter = () => {
    setSecondaryHovered(true);
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
  };

  const handleSecondaryMouseLeave = () => {
    setSecondaryHovered(false);
    setHoveredOption(null);
  };

  const handleMouseEnter = (event, option) => {
    setHoveredOption(option);
    if (event.target) {
      const rect = event.target.getBoundingClientRect();
      const dropdownTop = rect.top - dropdownRef.current.getBoundingClientRect().top;
      setSecondaryDropdownStyle({
        top: `${dropdownTop}px`,
      });
    }
  };

  const toggleDropdown = () => {
    if (isDropdownOpen) {
      // If closing the dropdown, reset hovered states
      setHoveredOption(null);
      setSecondaryHovered(false);
    }
    setDropdownOpen(!isDropdownOpen);
  };
  

  return (
    <div ref={dropdownRef} className="relative inline-block text-left w-full">
      {/* Dropdown Button */}
      <div onClick={toggleDropdown}>
        <button
          type="button"
          className={`flex justify-between items-center w-full h-${h} border ${bcol} px-3 py-2 text-sm text-gray-700 leading-6 font-medium tracking-wide outline-none shadow-sm hover:shadow-md transition-all ${
            isDropdownOpen ? "bg-gray-200" : "bg-white"
          }`}
          aria-haspopup="true"
          aria-expanded={isDropdownOpen}
        >
          {displayChecked.length > 25
            ? `${displayChecked.slice(0, 25)}...`
            : displayChecked}
          <AiOutlineDown size={14} />
        </button>
      </div>

      {/* Primary Dropdown */}
      {isDropdownOpen && (
        <div
          className="absolute left-0 mt-0 w-full bg-white shadow-lg border border-gray-200 z-40"
          onMouseLeave={handlePrimaryMouseLeave}
        >
          <div
            className="py-2 text-xl text-gray-700 max-h-[240px] overflow-y-auto"
            role="menu"
            aria-orientation="vertical"
          >
            {options.map((option) => (
              <div
                key={option._id}
                className={`flex items-center justify-between p-2 cursor-pointer transition-all hover:bg-gray-100 ${
                  hoveredOption === option ? "bg-gray-100" : ""
                }`}
                onMouseEnter={(e) => handleMouseEnter(e, option)}
              >
                <div className="flex items-center">
                  <span className="text-xs truncate text-gray-700">
                    {option.label}
                  </span>
                </div>
                {option.productcategoryIds &&
                  option.productcategoryIds.length > 0 && (
                    <AiOutlineRight size={14} />
                  )}
              </div>
            ))}
            {/* Hardcoded "Lobby Page" Option */}
            <div
              className="flex items-center p-2 cursor-pointer transition-all hover:bg-gray-100"
              onClick={() => handleOptionClick("Lobby Page")}
              onMouseEnter={() => setHoveredOption(null)}
            >
              <span className="text-xs text-gray-700 truncate">Lobby Page</span>
            </div>
          </div>
        </div>
      )}

      {/* Secondary Dropdown */}
      {hoveredOption && hoveredOption.productcategoryIds?.[0]?.dashboardIds && (
        <div
          className="absolute left-full ml-1 w-[250px] bg-white shadow-lg border border-gray-200 z-50"
          style={secondaryDropdownStyle}
          onMouseEnter={handleSecondaryMouseEnter}
          onMouseLeave={handleSecondaryMouseLeave}
        >
        <div className="py-2 max-h-[200px] overflow-y-auto text-sm text-gray-700">
  {hoveredOption.productcategoryIds.map((productCategory, productIndex) => (
    <div key={`product-${productIndex}`}>
      {productCategory.dashboardIds.map((dashboard, dashboardIndex) => (
        <div
          key={`${productIndex}-${dashboardIndex}`}
          onClick={(e) => {
            e.stopPropagation();
            handleOptionClick(dashboard.dashboardname);
          }}
          className="p-2 text-xs text-gray-700 cursor-pointer hover:bg-gray-100 transition-all"
        >
          {dashboard.dashboardname}
        </div>
      ))}
    </div>
  ))}
</div>

        </div>
      )}
    </div>
  );
};




export default memo(TicketDropDown);

