// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo } from "react";
import GeneralButton from "../buttons/GeneralButton";

import { RxCross1 } from "react-icons/rx";
import { BiErrorCircle } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { acceptError } from "../../store/auth/action";

function ActionError({ errorMsg }) {
  const dispatch = useDispatch();

  const { error } = useSelector((state) => ({
    error: state.error,
    failure: state.failure,
  }));

  return (
    <div className="z-[100] fixed w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 bottom-0 left-0 right-0">
      <div
        className="w-[500px] h-[220px] bg-[#ffffff] shadow  mt-40 mx-auto "
        id="modal"
      >
        <div className="p-8 ">
          <div className="flex Roboto justify-between">
            <div className="font-bold text-[#333333] flex gap-1">
              <BiErrorCircle className="mt-1" />

              <div className="Roboto">Error!</div>
            </div>

            <div>
              <RxCross1
                className="cursor-pointer"
                onClick={() => dispatch(acceptError())}
              />
            </div>
          </div>

          <div className="text-left my-7 Roboto">
            <p className="Roboto text-left">{errorMsg || error}</p>
          </div>

          <div className="flex justify-center">
            <GeneralButton
              text={"Okay"}
              onclick={() => dispatch(acceptError())}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ActionError);
