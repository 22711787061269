// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { getSession } from "../../utils/sessionstore";
import { useNavigate } from "react-router-dom";
import ActionError from "../errors/ActionError";
import StartLoader from "../loaders/StartLoader";
import { getTicket } from "../../services/ticket.service";

function TicketDetail({ onCancel, ticketId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [priority, setPriority] = useState({});
  const [status, setStatus] = useState({});
  const [issue, setIssue] = useState("");
  const [comment, setComment] = useState("");
  const [date, setDate] = useState(new Date());
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [ticketNumber, setTicketNumber] = useState("");

  const navigate = useNavigate();

  async function populateTicket(id) {
    try {
      const token = getSession("session_hwai");
      if (!token) {
        navigate("/login");
      } else {
        setIsLoading(true); // Start loader
        const ticketresponse = await getTicket(id);

        setIssue(ticketresponse?.detail);
        setCategory(ticketresponse?.feature);
        setSubCategory(ticketresponse?.ticketType);
        setComment(ticketresponse?.comment);
        setDate(ticketresponse?.raiseDate);
        setPriority({ name: ticketresponse?.priority });
        setStatus({ name: ticketresponse?.status });
        setTicketNumber(ticketresponse?.customTicketId);
      }
    } catch (e) {
      setFailure(true); // Show error
    } finally {
      setIsLoading(false); // Stop loader
    }
  }

  useEffect(() => {
    populateTicket(ticketId);
  }, [ticketId]);

  return (
    <div className="z-[100] fixed w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 bottom-0 right-0 flex items-center justify-center">
      <div className="bg-[#ffffff] shadow absolute w-[496px]" id="modal">
        <div className="px-[32px] py-[24px]">
          <div className="flex Roboto justify-between">
            <div className="text-zinc-800 text-base font-semibold font-['Roboto'] leading-tight tracking-wide">
              Ticket Details
            </div>
            <div className="text-right cursor-pointer" onClick={onCancel}>
              <RxCross1 />
            </div>
          </div>

          {/* grid inputs */}
          <div className="grid grid-cols-2 mt-[24px] gap-y-[24px] gap-x-[18px]">
            <div className="flex flex-col gap-[4px] Roboto">
              <div className="text-zinc-500 text-[11px] font-semibold uppercase leading-none tracking-wide">
                Ticket Id
              </div>
              <div className="text-zinc-800 text-[13px] font-normal leading-tight tracking-wide">
                {ticketNumber ? ticketNumber : ticketId}
              </div>
            </div>
            <div className="flex flex-col gap-[4px] Roboto">
              <div className="text-zinc-500 text-[11px] font-semibold uppercase leading-none tracking-wide">
                Feature
              </div>
              <div className="text-zinc-800 text-[13px] font-normal leading-tight tracking-wide">
                {category}
              </div>
            </div>

            <div className="flex flex-col col-span-2 gap-[4px] Roboto">
              <div className="text-zinc-500 text-[11px] font-semibold uppercase leading-none tracking-wide">
                Details
              </div>
              <div className="text-zinc-800 text-[13px] font-normal leading-tight tracking-wide">
                {issue}
              </div>
            </div>
          </div>
          {/* grid input ends */}
        </div>
      </div>

      {isLoading && <StartLoader />} {/* Show loader locally */}
      {failure && <ActionError />} {/* Show error locally */}
    </div>
  );
}

export default memo(TicketDetail);

