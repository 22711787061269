// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useEffect } from "react";

const ButtonOptionSelector = ({
  options,
  setChecked,
  checked,
  showwarning,
  setShowWarning,
}) => {
  const handleOptionClick = (option) => {
    setChecked(option);
    setShowWarning(false);
  };
  useEffect(() => {}, [checked]);

  return (
    <div className="flex gap-[4px]">
      {options.map((option, index) => (
        <div
          className={`${
            checked?.name === option?.name
              ? "text-zinc-800 bg-[#7D528B] bg-opacity-10 border-[#7D528B]"
              : "bg-white border-zinc-300 text-zinc-600"
          } 
                                h-5 px-2 rounded-[22px] border justify-center items-center gap-2.5 inline-flex cursor-pointer`}
          key={index}
          onClick={() => {
            handleOptionClick(option);
          }}
        >
          <div className=" text-[13px] font-normal font-['Roboto'] leading-tight tracking-wide">
            {option?.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(ButtonOptionSelector);
