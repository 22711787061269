// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import React, { useState, useRef } from "react";
import {
  MdChevronLeft,
  MdClose,
  MdHelpOutline,
  MdList,
  MdOutlineAdd,
  MdOutlineImage,
  MdOutlineRemove,
  MdOutlineSearch,
  MdRefresh,
  MdZoomIn,
  MdZoomOut,
} from "react-icons/md";
import "./richTextEditor.css";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

function Controls() {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  return (
    <div className="absolute scale-95 opacity-85 hover:opacity-100 hover:scale-100 ani bottom-2 bg-white flex flex-row gap-2 group-hover:opacity-100 ani border border-borderPrimary px-3 py-1 h-max">
      <MdZoomIn
        className="cursor-pointer text-[24px] hover:text-primary hover:scale-105 ani"
        onClick={() => zoomIn()}
      />
      <MdZoomOut
        className="cursor-pointer text-[24px] hover:text-primary hover:scale-105 ani"
        onClick={() => zoomOut()}
      />
      <MdRefresh
        className="cursor-pointer text-[24px] hover:text-primary hover:scale-105 ani"
        onClick={() => resetTransform()}
      />
    </div>
  );
}

export default function HelpGuide({
  sectionLog,
  open,
  setOpen,
  data,
  setData,
  chapterLog,
  setChapterLog,
}) {
  const [search, setSearch] = useState("");
  const sectionRefs = useRef({});
  const [maximizedImage, setMaximizedImage] = useState(null);
  const [maximizedImageLabel, setMaximizedImageLabel] = useState(null);
  const [maximizedImageSize, setMaximizedImageSize] = useState("large");

  const scrollToSection = (sectionId) => {
    sectionRefs.current[sectionId]?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      onClick={() => {
        setMaximizedImage(null);
        setMaximizedImageLabel(null);
        setMaximizedImageSize("large");
        setOpen(false);
      }}
      className={`fixed ani left-0 top-0 w-screen h-[100dvh] z-[100] flex justify-center items-center ${
        open
          ? "bg-[#00000018] backdrop-blur-sm"
          : "bg-transparent select-none pointer-events-none"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[70%] relative h-[90%] bg-[white] text-[#5C5C5C] px-8 py-6 flex flex-col gap-[13px] shrink-0 transition-all duration-200 ease-in-out ${
          open
            ? "opacity-100 blur-0"
            : "opacity-0 blur-sm scale-110 select-none pointer-events-none"
        }`}
      >
        {!!maximizedImage && (
          <div className="absolute top-0 left-0 z-10 w-full h-full filter backdrop-blur-md flex flex-col gap-[13px] px-8 py-6 bg-[#0000000f]">
            <div className="w-full flex justify-between items-center">
              <div className="font-semibold text-[16px] leading-[18px] flex flex-row items-center gap-1">
                <MdOutlineImage className="text-[20px]" />
                {maximizedImageLabel ? maximizedImageLabel : "Image Preview"}
              </div>
              <span className="flex flex-row gap-1">
                <button
                  onClick={() => {
                    setMaximizedImage(null);
                    setMaximizedImageLabel(null);
                    setMaximizedImageSize("large");
                  }}
                  className="transition-all duration-200 ease-in-out hover:-translate-x-1"
                >
                  <MdChevronLeft
                    className="text-[24px]"
                    aria-label="Close help guide"
                  />
                </button>
              </span>
            </div>
            <TransformWrapper>
              <div className="relative group w-full h-full flex items-center justify-center overflow-hidden">
                <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                  <img
                    src={maximizedImage}
                    style={{
                      maxWidth: `${
                        maximizedImageSize === "small"
                          ? "30%"
                          : maximizedImageSize === "medium"
                          ? "60%"
                          : "100%"
                      }`,
                    }}
                    className="cursor-pointer w-full h-full object-contain"
                    alt={maximizedImage || "Content image"}
                  />
                </TransformComponent>
                <Controls />
              </div>
            </TransformWrapper>
          </div>
        )}

        <div className="w-full flex justify-between items-center">
          <div className="font-semibold text-[16px] leading-[18px] flex flex-row items-center gap-1">
            <MdHelpOutline className="text-[20px]" />
            Help Guide
          </div>
          <button
            onClick={() => {
              setOpen(false);
            }}
            className="transition-all duration-200 ease-in-out hover:rotate-180"
          >
            <MdClose className="text-[20px]" aria-label="Close help guide" />
          </button>
        </div>
        <div className="flex-grow overflow-auto w-full flex flex-row box-content border border-[#E9E8E8]">
          <div className="w-[270px] h-full flex flex-col border-r border-[#E9E8E8] shrink-0">
            <div className="w-full h-[37px] shrink-0 font-semibold text-[#7D7D7D] text-[11px] uppercase flex flex-row items-center gap-1 border-b border-[#E9E8E8] p-2">
              <MdList className="text-[20px]" />
              Index
            </div>
            <div className="flex-grow overflow-y-auto overflow-x-clip flex flex-col w-full">
              <div className="w-full relative flex flex-row items-center px-2 py-1">
                <MdOutlineSearch className="absolute left-4 text-[20px] text-[#DDDDDC] select-none pointer-events-none" />
                <input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  placeholder="Search..."
                  className="h-[28px] w-full pl-8 py-1 pr-1 placeholder:text-[#DDDDDC] text-[13px] outline-none border-[#DDDDDC] border"
                />
                {search !== "" && (
                  <button
                    className="absolute right-4 text-[20px] text-[#818181] select-none cursor-pointer hover:text-[#3d3d3d]"
                    onClick={() => {
                      setSearch("");
                    }}
                    aria-label="Clear search"
                  >
                    <MdClose />
                  </button>
                )}
              </div>
              <div className="flex-grow w-full overflow-y-auto overflow-x-clip flex flex-col">
                {data?.chapters
                  ?.filter((chapter) => {
                    if (search.trim() === "") return true;
                    return (
                      chapter?.chapterTitle
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      chapter?.sections?.some(
                        (section) =>
                          section?.sectionTitle
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          section?.contents?.some((content) =>
                            content?.richText
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          )
                      )
                    );
                  })
                  .map((chapter) => (
                    <div
                      className="flex flex-col gap-0 select-none ani"
                      key={chapter?.id}
                    >
                      <div
                        title={`ID: ${chapter?.id}\nChapter Title: ${chapter?.chapterTitle}`}
                        onClick={() => {
                          console.log(chapter?.id);
                          scrollToSection(chapter?.id);
                          const updatedChapters = data?.chapters?.map((item) =>
                            item?.id === chapter?.id
                              ? { ...item, collapsed: !item?.collapsed }
                              : item
                          );
                          if (chapter?.collapsed)
                            chapterLog({
                              chapter: chapter?.chapterTitle,
                            });
                          setData({ ...data, chapters: updatedChapters });
                        }}
                        className={`cursor-pointer px-2 hover:bg-bgHoverGrey w-full flex flex-row gap-2 justify-between items-start h-max py-[5.5px] border-b border-borderPrimary pr-2 ani`}
                      >
                        <div className="overflow-x-auto flex flex-row items-start gap-2">
                          <img
                            className="w-5 h-5 object-contain shrink-0 mt-[2px]"
                            src={
                              chapter?.chapterIconUrl || "/imageNotFound.png"
                            }
                            alt={`Icon for ${chapter?.chapterTitle}`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/imageNotFound.png";
                            }}
                          />
                          <p className="text-[13px] text-textGrey text-left">
                            {chapter?.chapterTitle}
                          </p>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          {chapter?.collapsed ? (
                            <MdOutlineAdd
                              className="text-[20px] shrink-0 cursor-pointer"
                              aria-label="Expand chapter"
                            />
                          ) : (
                            <MdOutlineRemove
                              className="text-[20px] shrink-0 cursor-pointer"
                              aria-label="Collapse chapter"
                            />
                          )}
                        </div>
                      </div>

                      {!chapter?.collapsed &&
                        chapter?.sections
                          .filter((section) => {
                            if (search.trim() === "") return true;
                            return (
                              section?.sectionTitle
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              section?.contents?.some((content) =>
                                content?.richText
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              )
                            );
                          })
                          .map((section) => (
                            <div
                              className="flex flex-col ani gap-0"
                              key={section?.id}
                            >
                              <div
                                title={`ID: ${section?.id}\nSection Title: ${section?.sectionTitle}`}
                                onClick={() => {
                                  sectionLog({
                                    chapter: chapter?.chapterTitle,
                                    section: section?.sectionTitle,
                                  });
                                  scrollToSection(section?.id);
                                }}
                                className={`cursor-pointer hover:bg-bgPrimaryLight w-full flex flex-row gap-2 justify-start items-start pr-2 pl-8 ani overflow-hidden border-b border-borderPrimary py-[5.5px]`}
                              >
                                <div className="overflow-x-auto flex flex-row items-start gap-2">
                                  <p className="text-[13px] text-textBlack text-left">
                                    {section?.sectionTitle}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex-grow overflow-auto h-full flex flex-col">
            <div className="w-full h-[37px] text-[#5C5C5C] shrink-0 font-semibold text-[11px] uppercase flex flex-row items-center gap-2 border-b border-[#E9E8E8] p-2">
              <img
                className="w-[20px] h-[20px] self-start"
                src={
                  "https://lobbystore.blob.core.windows.net/lobby-adminpanel/icon-park-outline_add-subset.png"
                }
                alt="Section breakdown icon"
              />
              Section Breakdown
            </div>
            <div className="flex-grow overflow-y-auto overflow-x-clip flex flex-col px-8 py-4 gap-2">
              {data?.chapters?.map((chapter) => {
                return (
                  <div
                    key={chapter?.id}
                    className="flex flex-col gap-4 border-b border-borderPrimary pb-4 pt-1"
                  >
                    <div
                      key={chapter?.id}
                      ref={(el) => {
                        sectionRefs.current[chapter?.id] = el;
                      }}
                    >
                      <div className="w-full h-max text-[#333333] text-left shrink-0 font-bold underline text-[17px] flex flex-row items-start gap-2">
                        {chapter?.chapterTitle}
                      </div>
                      {chapter?.contents?.map((content, index) => (
                        <div
                          key={content?.id || index}
                          className="mt-4 flex flex-col items-center"
                        >
                          {content?.type === "image" &&
                            content?.imageUrl &&
                            content?.imageUrl.trim() !== "" && (
                              <img
                                onClick={() => {
                                  if (maximizedImage === content.imageUrl) {
                                    setMaximizedImage(null);
                                    setMaximizedImageLabel(null);
                                    setMaximizedImageSize("large");
                                  } else {
                                    setMaximizedImage(content.imageUrl);
                                    setMaximizedImageLabel(content.imageLabel);
                                    setMaximizedImageSize(content.imageSize);
                                  }
                                }}
                                src={content?.imageUrl}
                                className="cursor-pointer h-auto"
                                style={{
                                  maxWidth: `${
                                    content?.imageSize === "small"
                                      ? "30%"
                                      : content?.imageSize === "medium"
                                      ? "60%"
                                      : "100%"
                                  }`,
                                }}
                                alt={content?.imageLabel || "Content image"}
                              />
                            )}
                          {content?.type === "image" && content?.imageLabel && (
                            <p className="w-full text-center text-[11px] uppercase text-[#5C5C5C] mt-2">
                              {content?.imageLabel}
                            </p>
                          )}
                          {content?.type === "paragraph" &&
                            content?.richText && (
                              <div className="ql-container ql-snow w-full ql-container-override h-max mt-4">
                                <div
                                  className="ql-editor ql-editor-override h-max text-[13px]"
                                  dangerouslySetInnerHTML={{
                                    __html: content?.richText || "<p></p>",
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                    <div key={chapter?.id} className="flex flex-col gap-4">
                      {chapter?.sections?.map((section) => (
                        <div
                          key={section?.id}
                          ref={(el) => {
                            sectionRefs.current[section?.id] = el;
                          }}
                        >
                          <div className="w-full h-max text-[#333333] text-left shrink-0 font-semibold text-[15px] flex flex-row items-start gap-2">
                            {section?.sectionTitle}
                          </div>
                          {section?.contents?.map((content, index) => (
                            <div
                              key={content?.id || index}
                              className="mt-4 flex flex-col items-center"
                            >
                              {content?.type === "image" &&
                                content?.imageUrl &&
                                content?.imageUrl.trim() !== "" && (
                                  <img
                                    onClick={() => {
                                      if (maximizedImage === content.imageUrl) {
                                        setMaximizedImage(null);
                                        setMaximizedImageLabel(null);
                                        setMaximizedImageSize("large");
                                      } else {
                                        setMaximizedImage(content.imageUrl);
                                        setMaximizedImageLabel(
                                          content?.imageLabel
                                        );
                                        setMaximizedImageSize(
                                          content?.imageSize
                                        );
                                      }
                                    }}
                                    src={content?.imageUrl}
                                    className="cursor-pointer h-auto"
                                    style={{
                                      maxWidth: `${
                                        content?.imageSize === "small"
                                          ? "30%"
                                          : content?.imageSize === "medium"
                                          ? "60%"
                                          : "100%"
                                      }`,
                                    }}
                                    alt={content?.imageLabel || "Content image"}
                                  />
                                )}
                              {content?.type === "image" &&
                                content?.imageLabel && (
                                  <p className="w-full text-center text-[11px] uppercase text-[#5C5C5C] mt-2">
                                    {content?.imageLabel}
                                  </p>
                                )}
                              {content?.type === "paragraph" &&
                                content?.richText && (
                                  <div className="ql-container ql-snow w-full ql-container-override h-max mt-4">
                                    <div
                                      className="ql-editor ql-editor-override h-max text-[13px]"
                                      dangerouslySetInnerHTML={{
                                        __html: content?.richText || "<p></p>",
                                      }}
                                    />
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
