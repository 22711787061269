// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo } from "react";
import GeneralButton from "../buttons/GeneralButton";
import { MdAddCircleOutline, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { userLogsAPI } from "../../services/session.service";
import TicketDropDown from "../dropdowns/TicketDropDown";
import {
  getTicketFeatureAPI,
  getTcketTypeAPI,
} from "../../services/ticket.service";
import { invokeSuccess } from "../../store/auth/action";
import SuccessPop from "../popups/SuccessPop";

import StartLoader from "../loaders/StartLoader";
import { listTickets, raiseTicket } from "../../services/ticket.service";

import { getSession } from "../../utils/sessionstore";
import ButtonOptionSelector from "../dropdowns/ButtonOptionSelector";
import RequiredError from "../errors/RequiredError";
import TransaparentButton from "../buttons/TransaparentButton";
import ViewTicketSection from "../sections/ViewTicketSection";

const CreateTicketPopup = ({ searchedResult, setSearchedResult,closePopup }) => {
  const {subscriptions, success, isLoading, userDetails, clientname,orgs } = useSelector(
    (state) => ({
      subscriptions:state.subscriptions,
      success: state.success,
      isLoading: state.isLoading,
      userDetails: state.userDetails,
      clientname: state.clientname,
      orgs:state.orgs,
    })
  );

  const sessionId = getSession("session_id");
  const token = getSession("session_hwai");
  const clientid = getSession("client_id");

  async function callUserLogAPI() {
    try {
      const logresponse = userLogsAPI(
        "TICKET",
        "Ticket",
        userDetails,
        clientname,
        "Ticket",
        "Viewed"
      );
      // console.log(logresponse, "banner click");
    } catch (err) {
      console.log(err.message);
    }
  }

  async function populateTicketVeiw() {
    const response = await listTickets();
    setSearchedResult(response.tickets);
  }

  const [selectedFeature, setSelectedFeature] = useState("");
  const [currentView, setCurrentView] = useState("create");

  const [feature, setFeature] = useState([]);
  const [detail, setDetail] = useState("");
  const [priority, setPriority] = useState({});

  const [featureWarning, setFeatureWarning] = useState(false);
  const [detailWarning, setDetailWarning] = useState(false);
  const [priorityWarning, setPriorityWarning] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewHistory = () => {
    setCurrentView("viewHistory");
  };

  const handleGoBack = () => {
    setCurrentView("create");
  };
  

  async function populateFeature() {
    setFeature(subscriptions);
  }
  useEffect(() => {
    populateFeature();

    callUserLogAPI();
  }, []);

  useEffect(() => {
    if (success) {
        const timer = setTimeout(() => {
            dispatch({ type: "SET_SUCCESS", payload: false });
        }, 2000);
        return () => clearTimeout(timer);
    }
}, [success, dispatch]);

  async function saveDetails() {
    try {
        const token = getSession("session_hwai");
        if (!token) {
            navigate("/login");
        } else {
            // Validation
            if (
                !priority ||
                !priority?.name ||
                !detail.length > 0 ||
                !selectedFeature ||
                Object.keys(selectedFeature).length === 0 ||
                selectedFeature?.includes("-Select-")
            ) {
                if (!priority || !priority?.name) setPriorityWarning(true);
                if (!detail.length > 0) setDetailWarning(true);
                if (
                    !selectedFeature ||
                    Object.keys(selectedFeature).length === 0 ||
                    selectedFeature?.includes("-Select-")
                ) setFeatureWarning(true);
                return;
            }

            const payload = {
                feature: selectedFeature,
                detail: detail,
                priority: priority?.name,
                userId: token,
                clientId: clientid,
            };

            // Reset success/loading states
            dispatch({ type: "SET_LOADING", payload: true });
            dispatch({ type: "SET_SUCCESS", payload: false });

            const response = await raiseTicket(payload);

            // Clear form inputs immediately after successful submission
            setSelectedFeature( "-Select-" );
            setDetail("");
            setPriority({});
            populateTicketVeiw();

            // Ensure UI reflects success and no longer loading
            dispatch({ type: "SET_SUCCESS", payload: true });
            dispatch({ type: "SET_LOADING", payload: false });
        }
    } catch (err) {
        console.error(err);
    } finally {
        dispatch({ type: "SET_LOADING", payload: false });
    }
}

  // async function viewHistory() {
    
  
  //   // Check if the current URL contains the term "dashboard"
  //   if (window.location.href.includes('dashboard')) {
  //     const confirmLeave = window.confirm(
  //       "This action will redirect you to the ticket history page, any filters applied on this screen will be reset to their default settings"
  //     );
  
  //     if (!confirmLeave) {
  //       // If the user cancels, simply return and do nothing
  //       return;
  //     }
  //   }
  //   closePopup();
  
  //   // Proceed with the original logic
  //   if (
  //     clientid !== undefined &&
  //     orgs.map((org) => org._id).includes(clientid) &&
  //     userDetails?.permissions === "HWAI_ADMIN"
  //   ) {
  //     navigate(`/${clientid}/ticket`);
  //   } else {
  //     navigate("/ticket");
  //   }
  // }
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 z-[9999] flex items-center justify-center">
      {/* Modal Background */}
      <div className="relative bg-white w-[80%] max-w-[600px] shadow-lg p-[16px]">
        {/* Close Button */}
        <button
          className="absolute top-6 right-6 text-gray-500 hover:text-gray-700  text-1.5xl"
          onClick={closePopup}
        >
          <MdClose className="text-[20px]" aria-label="Close Ticekt Popup" />
        </button>

        {/* Modal Content */}
        {currentView=="create"&&(
        <div className="w-full h-full px-[32px] py-[28px] text-left ">
          <div className="flex mb-[24px]">
            <div className="flex items-center gap-[16px]">
              <MdAddCircleOutline className="font-extrabold text-2xl" />
              <div className="Roboto text-zinc-600 text-base font-semibold leading-tight tracking-wide">
                Create Ticket
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-[16px]">
            <div className="grid grid-cols-2 gap-10 w-full">
              {/* Feature Dropdown */}
              <div className="flex flex-col gap-[2px]">
                <p className="text-zinc-500 text-[11px] font-semibold Roboto uppercase leading-none tracking-wide">
                  Dashboard Name
                </p>
                <TicketDropDown
                  options={feature}
                  setCheck={setSelectedFeature}
                  check={selectedFeature}
                  showwarning={featureWarning}
                  setShowWarning={setFeatureWarning}
                />
                <div
                  className={`${featureWarning ? "" : "invisible"} h-[0px]`}
                >
                  <RequiredError error={"Required field missing"} />
                </div>
              </div>
              <div className="flex flex-col gap-2">
              <p className="text-zinc-500 text-[11px] font-semibold Roboto uppercase leading-none tracking-wide">
                Priority
              </p>
              <ButtonOptionSelector
                options={[
                  { name: "Low" },
                  { name: "Medium" },
                  { name: "High" },
                ]}
                setChecked={setPriority}
                checked={priority}
                showWarning={priorityWarning}
                setShowWarning={setPriorityWarning}
              />
              <div
                className={`${priorityWarning ? "" : "invisible"} h-[0px]`}
              >
                <RequiredError error={"Required field missing"} />
              </div>
            </div>
             
            </div>

            {/* Priority Selector */}

           
            

            {/* Details Textarea */}
            <div className="Roboto text-left w-full flex flex-col gap-1">
              <p className="text-zinc-500 text-[11px] font-semibold Roboto uppercase leading-none tracking-wide">
                Details
              </p>
              <textarea
                placeholder="Enter details here"
                className="h-[190px] placeholder:text-zinc-300 w-full p-[8px] text-[13px] focus:outline-none border leading-tight tracking-wide"
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
                onFocus={() => setDetailWarning(false)}
              />
              <div
                className={`${detailWarning ? "" : "invisible"} h-[0px]`}
              >
                <RequiredError error={"Required field missing"} />
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="text-right mt-[30px] flex flex-row-reverse justify-between">
            <GeneralButton text={"Submit"} onclick={saveDetails} />
            <TransaparentButton text={"View History"} onclick={handleViewHistory} />
          </div>
        </div>
        )}
          {currentView === "viewHistory" && (
        <ViewTicketSection searchedResult={searchedResult} setSearchedResult={setSearchedResult} onGoBack={handleGoBack} isPopup={true}/> // Render the view history component
      )}
          {success && <SuccessPop/> }
        {/* Loader */}
        {isLoading && <StartLoader />}
      </div>
    </div>
  );
  
};

export default memo(CreateTicketPopup);
