// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
export function checkPasswordStrength(password) {
  // Regular expressions to check for lowercase, uppercase, number, and special character
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
    password
  );
  const validLength = password.length >= 8;

  // Count the number of criteria met
  const criteriaMet = [
    hasLowercase,
    hasUppercase,
    hasNumber,
    hasSpecialChar,
    validLength,
  ].filter(Boolean).length;

  let stregthLevel = 0;
  // Determine password strength based on the number of criteria met
  if (criteriaMet === 5) {
    stregthLevel = 5;
  } else if (criteriaMet === 4) {
    stregthLevel = 4;
  } else if (criteriaMet === 3) {
    stregthLevel = 3;
  } else if (criteriaMet === 2) {
    stregthLevel = 2;
  } else if (criteriaMet === 1) {
    stregthLevel = 1;
  }

  return {
    hasLowercase,
    hasUppercase,
    hasNumber,
    hasSpecialChar,
    validLength,
    stregthLevel,
  };
}
