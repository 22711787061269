// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useEffect, useRef, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { MdCheck, MdChevronRight } from "react-icons/md";
import SliderButton from "../buttons/SliderButton";
import { useLocation, useNavigate } from "react-router-dom";

const ToggleButton = ({ selected, onClick, type = null, label }) => {
  return (
    <div
      className={`flex flex-row justify-between ani gap-1 items-center px-2 h-[21px] cursor-pointer rounded-full border ${
        selected ? "border-primary" : "border-borderPrimary"
      } hover:bg-bgHoverGrey`}
      onClick={onClick}
    >
      {!!type && (
        <div
          className={`w-[6px] h-[6px] shrink-0 ani rounded-full ${
            type === "live" ? "bg-primary" : "bg-borderPrimary"
          }`}
        ></div>
      )}
      <p className="text-textGrey text-[11px] uppercase font-semibold tracking-[0.55px]">
        {label}
      </p>
    </div>
  );
};

const ClientSelectDropDown = ({
  checked,
  options,
  setChecked,
  apiLoad = false,
}) => {
  const pathParts = useLocation().pathname.split("/");
  const [displayChecked, setDisplayChecked] = useState("-Select-");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const clientRefs = useRef({});
  const clientListRef = useRef(null);

  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const scrollTimeoutRef = useRef(null);
  const autoScrollIntervalRef = useRef(null);

  const scrollToClient = (clientName) => {
    if (!isUserScrolling) {
      clientRefs.current[clientName]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleDocumentClick = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleOptionClick = (option) => {
    setChecked(option);
    setDisplayChecked(option.clientname);
  };

  const handleDropdownToggle = (e) => {
    e.stopPropagation();
    setDropdownOpen(!isDropdownOpen);
    if (!isDropdownOpen) {
      setSearchQuery("");
    }
  };

  const isOptionSelected = (option) => {
    const optionName = option.clientname;
    return (
      displayChecked === optionName ||
      (checked && checked.clientname === optionName)
    );
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (isDropdownOpen) {
      inputRef.current?.focus();
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    const dropdownContent = clientListRef.current;
    if (!dropdownContent) return;

    const handleScroll = () => {
      setIsUserScrolling(true);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      scrollTimeoutRef.current = setTimeout(() => {
        setIsUserScrolling(false);
      }, 5000);
    };

    dropdownContent.addEventListener("scroll", handleScroll);

    return () => {
      dropdownContent.removeEventListener("scroll", handleScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (checked && !isUserScrolling) {
      // Initial scroll when dropdown opens
      scrollToClient(checked.clientname);

      // Set up an interval to trigger the scroll every 5 seconds after user stops scrolling
      autoScrollIntervalRef.current = setInterval(() => {
        if (!isUserScrolling) {
          scrollToClient(checked.clientname);
        }
      }, 5000);
    }

    return () => {
      if (autoScrollIntervalRef.current) {
        clearInterval(autoScrollIntervalRef.current);
      }
    };
  }, [isDropdownOpen, displayChecked, checked, isUserScrolling]);

  useEffect(() => {
    const sS = localStorage.getItem("showSuggestions");
    setShowSuggestions(sS === "true");
    return () => {
      setShowSuggestions(false);
    };
  }, []);

  useEffect(() => {
    const cF = localStorage.getItem("categoryFilter");
    setCategoryFilter(cF === "null" ? "live" : cF);
    return () => {
      setCategoryFilter("live");
    };
  }, []);

  useEffect(() => {
    if (pathParts.includes("hwai-login")) {
      // const sC = localStorage.getItem("selectedClient");
      // if (sC) {
      //   handleOptionClick(JSON.parse(sC));
      // } else {
      const demoClient = options.find(
        (client) => client.name === "Demo" || client.clientname === "Demo"
      );
      if (demoClient && !isOptionSelected(demoClient)) {
        handleOptionClick(demoClient);
      }
      // }
    }
    return () => {};
  }, [options]);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left w-full">
      <div>
        <button
          type="button"
          onClick={(e) => {
            if (!apiLoad) handleDropdownToggle(e);
          }}
          className={`flex flex-row justify-start items-center w-full h-8 border border-borderPrimary px-2 py-1 text-sm leading-6 Roboto tracking-[0.7px] outline-none ${
            apiLoad ? "cursor-progress" : "cursor-pointer"
          }`}
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <div className="flex-grow relative flex flex-row h-full justify-start items-center">
            <p className="align-middle text-sm">
              {apiLoad ? "Loading..." : checked?.clientname || displayChecked}
            </p>
            <input
              ref={inputRef}
              type="text"
              className={`${
                isDropdownOpen
                  ? "block"
                  : "opacity-0 pointer-events-none select-none"
              } absolute top-0 left-0 z-10 h-full w-full p-1 text-sm leading-6 Roboto tracking-[0.7px] outline-none`}
              placeholder="Search..."
              value={searchQuery}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                const matchedOrg = options.find(
                  (org) =>
                    org.clientname.toLowerCase() ===
                    e.target.value.toLowerCase()
                );
                if (matchedOrg) {
                  setSearchQuery(matchedOrg.clientname);
                } else {
                  setSearchQuery(e.target.value);
                }
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  const matchedOrg = options.find(
                    (org) =>
                      org.clientname.toLowerCase() === searchQuery.toLowerCase()
                  );
                  if (matchedOrg) {
                    localStorage.setItem(
                      "selectedClient",
                      JSON.stringify(matchedOrg)
                    );
                    handleOptionClick(matchedOrg);
                    setDropdownOpen(false);
                  }
                }
              }}
            />
          </div>
          <div className="flex justify-center items-center w-6 h-6 shrink-0">
            <MdChevronRight
              className={`ani ${isDropdownOpen ? "rotate-90" : ""} ${
                apiLoad ? "opacity-0" : ""
              }`}
              size={20}
            />
          </div>
        </button>
      </div>

      <div
        className={`${
          isDropdownOpen
            ? ""
            : "translate-y-[-20%] opacity-0 pointer-events-none select-none"
        } ani origin-top absolute right-0 mt-[-1px] w-full shadow-lg bg-white border border-borderPrimary z-30`}
      >
        <div
          className={`${
            showSuggestions ? "h-8 border-b py-1" : "h-0 max-h-0 py-0"
          } overflow-y-clip ani gap-1 border-borderPrimary w-full px-1 shrink-0 flex  flex-row justify-start items-center`}
        >
          <ToggleButton
            key={"null"}
            label={"All"}
            selected={categoryFilter === null}
            onClick={() => {
              setCategoryFilter(null);
              localStorage.setItem("categoryFilter", null);
            }}
            type={null}
          />
          <ToggleButton
            key={"live"}
            label={"Live"}
            selected={categoryFilter === "live"}
            onClick={() => {
              setCategoryFilter("live");
              localStorage.setItem("categoryFilter", "live");
            }}
            type={"live"}
          />
          <ToggleButton
            key={"archived"}
            label={"Others"}
            selected={categoryFilter === "others"}
            onClick={() => {
              setCategoryFilter("others");
              localStorage.setItem("categoryFilter", "others");
            }}
            type={"others"}
          />
        </div>

        <div ref={clientListRef} className="max-h-[192px] overflow-y-auto ani">
          {showSuggestions
            ? options
                .filter((option) => {
                  const name = option.clientname;
                  return name.toLowerCase().includes(searchQuery.toLowerCase());
                })
                .map((option, index) => (
                  <div
                    ref={(el) => {
                      clientRefs.current[option.clientname] = el;
                    }}
                    key={index}
                    onClick={() => {
                      localStorage.setItem(
                        "selectedClient",
                        JSON.stringify(option)
                      );
                      handleOptionClick(option);
                      setDropdownOpen(false);
                    }}
                    className={`flex gap-1 ${
                      showSuggestions
                        ? !!categoryFilter
                          ? categoryFilter === option.category
                            ? "h-8 border-t"
                            : "h-0 border-t-0"
                          : "h-8 border-t"
                        : "h-0 border-t-0"
                    } pl-4 pr-2 border-l-[6px] ani overflow-y-clip ${
                      option.category === "live"
                        ? "border-l-primary"
                        : "border-l-borderPrimary"
                    } cursor-pointer border-borderPrimary flex-row justify-start items-center hover:bg-bgHoverGrey`}
                    role="menuitem"
                  >
                    <label className="text-sm line-clamp-1 flex-grow text-black Roboto tracking-[0.7px] flex items-center cursor-pointer">
                      {option.clientname}
                    </label>
                    <MdCheck
                      className={`text-[20px] shrink-0 ani ${
                        isOptionSelected(option)
                          ? "text-success"
                          : "text-transparent"
                      }`}
                    />
                  </div>
                ))
            : options
                .filter((option) => {
                  const name = option.clientname;
                  return (
                    searchQuery.trim().length >= 3 &&
                    option.category === "live" &&
                    name.toLowerCase().includes(searchQuery.toLowerCase())
                  );
                })
                .map((option, index) => (
                  <div
                    ref={(el) => {
                      clientRefs.current[option.clientname] = el;
                    }}
                    key={index}
                    onClick={() => {
                      localStorage.setItem(
                        "selectedClient",
                        JSON.stringify(option)
                      );
                      handleOptionClick(option);
                      setDropdownOpen(false);
                    }}
                    className={`flex gap-1 ${
                      !showSuggestions ? "h-8 border-t" : "h-0 border-t-0"
                    } pl-4 pr-2 border-l-[6px] ani overflow-y-clip ${
                      option.category === "live"
                        ? "border-l-primary"
                        : "border-l-borderPrimary"
                    } cursor-pointer border-borderPrimary flex-row justify-start items-center hover:bg-bgHoverGrey`}
                    role="menuitem"
                  >
                    <label className="text-sm line-clamp-1 flex-grow text-black Roboto tracking-[0.7px] flex items-center cursor-pointer">
                      {option.clientname}
                    </label>
                    <MdCheck
                      className={`text-[20px] shrink-0 ani ${
                        isOptionSelected(option)
                          ? "text-success"
                          : "text-transparent"
                      }`}
                    />
                  </div>
                ))}
        </div>

        <div className="h-8 border-t border-borderPrimary w-full shrink-0 flex p-1 flex-row justify-center gap-[10px] items-center">
          <p className="text-xs">Show Suggestions</p>
          <SliderButton
            key={"showSuggestions"}
            setIsChecked={(val) => {
              setSearchQuery("");
              setShowSuggestions(val);
              localStorage.setItem("showSuggestions", val);
            }}
            isChecked={showSuggestions}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ClientSelectDropDown);
