// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
"use client";

import { MdCheck } from "react-icons/md";

export default function SliderButton({ isChecked = false, setIsChecked }) {
  const handleToggle = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
  };

  return (
    <button
      role="switch"
      aria-checked={isChecked}
      onClick={handleToggle}
      className={`
        relative h-5 w-10 rounded-full px-[2px] transition-colors duration-200
        ${isChecked ? "bg-green-500" : "bg-gray-200"}
        focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
        focus-visible:ring-green-500
      `}
    >
      {/* <span className="sr-only">{isChecked ? "On" : "Off"}</span> */}
      <div
        className={`
          flex h-4 w-4 items-center justify-center rounded-full bg-white
          shadow-sm transition-all duration-200 ease-in-out
          ${isChecked ? "translate-x-5" : "translate-x-0"}
        `}
      >
        <MdCheck
          className={`
            h-4 w-4 transition-all duration-200
            ${isChecked ? "scale-100 opacity-100" : "scale-0 opacity-0"}
            ${isChecked ? "text-green-500" : "text-transparent"}
          `}
        />
      </div>
      <span
        className={`
          absolute right-1.5 top-1/2 -translate-y-1/2 text-[9px] font-medium text-[#6b6b6b]
          transition-all duration-200
          ${isChecked ? "opacity-0" : "opacity-100"}
        `}
      >
        Off
      </span>
      <span
        className={`
          absolute left-1.5 top-1/2 -translate-y-1/2 text-[9px] font-medium text-white
          transition-all duration-200
          ${isChecked ? "opacity-100" : "opacity-0"}
        `}
      >
        On
      </span>
    </button>
  );
}
