// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useEffect } from "react";

import logo from "../../img/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import ExpiredSession from "../../views/ExpiredSession";
import { useState } from "react";

import InputError from "../errors/InputError";
import { resetPassword, verifySession } from "../../services/auth.service";
import {
  acceptSuccess,
  invokeError,
  invokeSuccess,
  loginFailure,
} from "../../store/auth/action";
import SuccessPop from "../popups/SuccessPop";

import { useNavigate, useParams } from "react-router-dom";
import StartLoader from "../loaders/StartLoader";
import ActionError from "../errors/ActionError";
import PasswordStrengthLoader, {
  PasswordStrengthCriterias,
} from "../arul/passwordStrengthLoader";
import { checkPasswordStrength } from "../../utils/functions";
import AuthError from "../errors/AuthError";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const ResetPassword = () => {
  const { error } = useSelector((state) => ({
    error: state.error,
  }));
  const { success, isLoading, failure } = useSelector((state) => ({
    success: state.success,
    isLoading: state.isLoading,
    failure: state.failure,
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { session_id } = useParams();
  const [sessionExist, setSessionExist] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [apiLoad, setApiLoad] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState(null);

  const [errorText, setErrorText] = useState("Link has expired.");

  const [confirmTypeStart, setConfirmTypeStart] = useState(false);

  async function sessionVerification() {
    setApiLoad(true);
    try {
      const res = await verifySession({ session_id: session_id });
      // console.log(res, "res");
      if (res.isValid !== true) {
        setErrorText("Link has expired.");
        setSessionExist(false);
      } else {
        setPasswordPolicy(res.passwordPolicy);
      }
    } catch (error) {
      console.log(error.response.data, "error");
      setErrorText(
        error.response.data ||
          "Error occured during verification. Contact support."
      );
      setSessionExist(false);
    }
    setApiLoad(false);
  }

  useEffect(() => {
    sessionVerification();
  }, [session_id]);

  async function SaveDetails() {
    try {
      const payload = { password: password, confirmPassword: confirmPassword };

      const response = await resetPassword(payload);
      dispatch(invokeSuccess("Password updated !"));

      const timer = setTimeout(() => {
        dispatch(acceptSuccess());
        navigate("/login");
      }, 2000); // Set the timer duration in milliseconds

      return () => clearTimeout(timer);
    } catch (err) {
      console.log(err, "error");

      if (err?.response?.data?.includes("passwords cannot be reused")) {
        dispatch(
          loginFailure(
            err?.response?.data || "Something went wrong. Contact support."
          )
        );
      } else {
        setErrorText(
          err?.response?.data || "Something went wrong. Contact support."
        );
        setSessionExist(false);
      }
    }
  }

  return (
    <>
      {error.length > 0 && <AuthError error={error} />}

      {!sessionExist ? (
        <ExpiredSession text={errorText} />
      ) : (
        <div className="h-screen bg-[#ffffff] w-screen flex items-center justify-center ">
          <div className="flex flex-col items-center justify-center gap-4">
            <div className=" flex flex-col items-center justify-center border-2  w-max h-max p-14 gap-6">
              <div className="text-center flex flex-col justify-center w-[306px] h-max">
                <div className="flex justify-center w-full h-max px-6 pb-4">
                  <img alt="logo" src={logo} className="object-cover" />
                </div>
              </div>
              {/* form starts */}
              <div className="Roboto w-max h-max ">
                <div className="flex w-max">
                  <div className="flex flex-col text-left gap-4 w-max">
                    <div className="flex flex-col gap-1 relative">
                      <div className="flex gap-2">
                        <p className="text-[#7D7D7D] uppercase text-[11px] font-semibold Roboto tracking-[0.7px]">
                          New password
                        </p>
                        <span className="text-[#DC3545]">*</span>
                      </div>
                      <div className="flex items-center">
                        <input
                          disabled={apiLoad}
                          value={password}
                          placeholder="Enter password"
                          id="npassword"
                          name="npassword"
                          className="w-full p-1 px-2 leading-5 text-black text-[13px] focus:outline-none   border"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          autoComplete="off"
                          aut
                        />
                      </div>
                      <PasswordStrengthLoader password={password} />
                    </div>

                    <div className="flex flex-col gap-1 mt-1">
                      <div className="flex gap-2">
                        <p className="text-[#7D7D7D] uppercase text-[11px] font-semibold Roboto tracking-[0.7px]">
                          Confirm password
                        </p>
                        <span className="text-[#DC3545]">*</span>
                      </div>
                      <div className="flex items-center">
                        <input
                          disabled={apiLoad}
                          onFocus={() => setConfirmTypeStart(true)}
                          onBlur={() => {
                            if (confirmPassword === password)
                              setConfirmTypeStart(false);
                          }}
                          value={confirmPassword}
                          placeholder="Confirm password"
                          type="password"
                          id="cpassword"
                          name="cpassword"
                          className={`w-full ani p-1 px-2 leading-5 text-black text-[13px] focus:outline-none border ${
                            confirmTypeStart
                              ? confirmPassword !== password
                                ? "border-[#cf1717]"
                                : "border-[#36C565]"
                              : ""
                          }`}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <PasswordStrengthCriterias
                      marginTop={-2}
                      password={password}
                    />

                    {/* {password.length < 6 &&
                      password.length > 0 &&
                      confirmPassword.length > 0 && (
                        <InputError error={"Password is too short"} />
                      )} */}
                    {/* {!(password === confirmPassword) && (
                      <InputError error={"Passwords are not matching"} />
                    )} */}

                    <button
                      type="submit"
                      className={`mt-2 ${
                        (passwordPolicy?.level !== "normal" &&
                          checkPasswordStrength(password).stregthLevel !== 5) ||
                        password !== confirmPassword ||
                        password.trim() === "" ||
                        !checkPasswordStrength(password).validLength
                          ? "bg-[#BDA7C4] hover:bg-[#BDA7C4]"
                          : "bg-[#5C276E] hover:bg-[#4A1E57]"
                      } text-[#ffffff] Roboto w-[306px] ani h-[40px] tracking-[0.8px]`}
                      onClick={() => SaveDetails()}
                      disabled={
                        (passwordPolicy?.level !== "normal" &&
                          checkPasswordStrength(password).stregthLevel !== 5) ||
                        password !== confirmPassword ||
                        apiLoad ||
                        password.trim() === "" ||
                        !checkPasswordStrength(password).validLength
                      }
                    >
                      Reset Password
                    </button>

                    {/* Remember and forget password */}
                  </div>
                </div>
              </div>
              {/*form ends  */}
            </div>
          </div>
        </div>
      )}
      {success && <SuccessPop />}
      {failure && <ActionError errorMsg="Something Went Wrong" />}
      {isLoading && <StartLoader />}
    </>
  );
};

export default memo(ResetPassword);
