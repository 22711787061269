// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { invokeLoad, stopLoad } from "../../store/auth/action";
import { listTickets, searchTicketIssue } from "../../services/ticket.service";
import ActionError from "../errors/ActionError";
import StartLoader from "../loaders/StartLoader";
import TicketTable from "../tables/TicketTable";
import { IoMdClose, IoMdSearch } from "react-icons/io";

import SuccessPop from "../popups/SuccessPop";
import { getSession } from "../../utils/sessionstore";
import { MdHistory } from "react-icons/md";
import TransaparentButton from "../buttons/TransaparentButton";
import GeneralButton from "../buttons/GeneralButton";
const ViewTickets = ({ searchedResult, setSearchedResult,onGoBack,isPopup}) => {
  const { success, isLoading, failure, userDetails, clientname } = useSelector(
    (state) => ({
      success: state.success,
      isLoading: state.isLoading,
      userDetails: state.userDetails,
      clientname: state.clientname,
    })
  );

  const [searchedIssue, setSearchedIssue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getSearchDetail(searchterm) {
    if (searchterm.length > 0) {
      const response = await searchTicketIssue(searchterm);

      setSearchedResult(response.tickets);
    } else {
      populateTicketVeiw();
    }
    setCurrentPage(1);
  }

  function getSearchedIssues() {
    const token = getSession("session_hwai");
    if (!token) {
      navigate("/login");
    } else {
      getSearchDetail(searchedIssue);
    }
  }

  async function populateTicketVeiw() {
    const response = await listTickets();
    setSearchedResult(response.tickets);
  }

  useEffect(() => {
    const token = getSession("session_hwai");
    if (!token) {
      navigate("/login");
    } else {
      dispatch(invokeLoad());
      populateTicketVeiw();
      dispatch(stopLoad());
    }
  }, []);

  return (
    <>
  <div className="w-full px-[32px] py-[28px] text-left">
    {/* Heading  View Ticket */}
    <div className="flex flex-col mb-[24px] gap-[8px]">
      <div className="flex items-center gap-[16px]">
        <MdHistory className="font-extrabold text-2xl" />
        <div className="Roboto text-zinc-600 text-base font-semibold leading-tight tracking-wide">
          Ticket History
        </div>
      </div>
    </div>
    {/* Heading end  View Ticket */}

    <div className="flex flex-col gap-4">
      {/* table */}
      <div className="flex justify-start items-center Roboto gap-[8px]">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-[8px] pointer-events-none">
            <IoMdSearch className="text-zinc-300 h-4 w-4" />
          </div>
          <input
            placeholder="Search..."
            onChange={(e) => setSearchedIssue(e.target.value)}
            value={searchedIssue}
            className="border border-zinc-300 pl-8 pr-[8px] py-[4px] w-full text-[13px] text-zinc-800 leading-tight tracking-wide placeholder:text-zinc-300 focus:outline-none"
          />
          {searchedIssue && (
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-[8px] cursor-pointer"
              onClick={() => setSearchedIssue("")}
            >
              <IoMdClose className="text-zinc-800 h-4 w-4" />
            </div>
          )}
        </div>

        <TransaparentButton text={"Search"} onclick={getSearchedIssues} />
      </div>

      <div className="border-[1px] border-zinc-300 px-[8px] pb-[8px]">
        <TicketTable
          data={searchedResult}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {isPopup && (
        <div className="flex justify-start">
          <GeneralButton text={"Hide History"} onclick={onGoBack} />
        </div>
      )}
    </div>
  </div>

  {success && <SuccessPop />}
  {isLoading && <StartLoader />}
  {failure && <ActionError />}
</>
  );
};

export default memo(ViewTickets);
