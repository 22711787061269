// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { MdCheckCircle, MdCircle } from "react-icons/md";
import { checkPasswordStrength } from "../../utils/functions";

export default function PasswordStrengthLoader({ password }) {
  return (
    <div className="absolute top-full mt-1 w-full h-[2px] bg-[#ececec]">
      <div
        className={`ani rounded-full h-full ${
          checkPasswordStrength(password).stregthLevel != 0
            ? checkPasswordStrength(password).stregthLevel == 5
              ? "w-full bg-[#36C565]"
              : checkPasswordStrength(password).stregthLevel == 4
              ? "w-4/5 bg-[#1493FF]"
              : checkPasswordStrength(password).stregthLevel == 3
              ? "w-3/5 bg-[#F99F4A]"
              : checkPasswordStrength(password).stregthLevel == 2
              ? "w-2/5 bg-[#ff746a]"
              : checkPasswordStrength(password).stregthLevel == 1
              ? "w-1 bg-[#E44242]"
              : "w-1 bg-[#cf1717]"
            : "w-1 bg-[#cf1717]"
        }`}
      />
    </div>
  );
}

export function PasswordStrengthCriterias({ password, marginTop = 16 }) {
  const Icon = ({bool}) => (
    <div className="flex text-inherit h-5 w-5 shrink-0 justify-center relative items-center">
      <MdCheckCircle
        className={`${
          bool ? "" : "opacity-0"
        } ani absolute z-20 text-[18px] text-[#28A745] pointer-events-none`}
      />
      <MdCircle
        className={`${
          bool ? "opacity-0" : ""
        } ani absolute z-10 text-[18px] text-inherit text-borderPrimary pointer-events-none`}
      />
    </div>
  );
  return (
    <div
      style={{ marginTop: `${marginTop}px` }}
      className="select-none w-full flex flex-col h-max gap-2 text-xs text-textGrey"
    >
      <p className="text-sm">Password should contain at least</p>
      <span className="flex flex-row gap-2 justify-start items-center">
        <Icon bool={checkPasswordStrength(password).validLength} />
        <p className="text-sm">8 characters</p>
      </span>
      <span className="flex flex-row gap-2 justify-start items-center">
        <Icon bool={checkPasswordStrength(password).hasLowercase} />
        <p className="text-sm">one lowercase letter</p>
      </span>
      <span className="flex flex-row gap-2 justify-start items-center">
        <Icon bool={checkPasswordStrength(password).hasUppercase} />
        <p className="text-sm">one uppercase letter</p>
      </span>
      <span className="flex flex-row gap-2 justify-start items-center">
        <Icon bool={checkPasswordStrength(password).hasNumber} />
        <p className="text-sm">one number</p>
      </span>
      <span className="flex flex-row gap-2 justify-start items-center">
        <Icon bool={checkPasswordStrength(password).hasSpecialChar} />
        <p className="text-sm">one special character</p>
      </span>
    </div>
  );
}
