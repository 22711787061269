// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import axios from "axios";
import { setSession } from "../utils/sessionstore";
const API_ENDPOINT = require("../config/server");
const { getSession } = require("../utils/sessionstore");

export async function getTcketTypeAPI() {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_TICKET_TYPE",
      Method: "GET",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function getTicketFeatureAPI(clientId) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_TICKET_FEATURE",
      Method: "GET",
    },

    Parameters: {
      clientId: clientId,
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function getTicketCategory() {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_TICKET_CATEGORY",
      Method: "GET",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function searchTicketIssue(searchTerm) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "SEARCH_TICKET_ISSUE",
      Method: "GET",
    },

    Parameters: {
      search: searchTerm,
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function getTicketSubCategory(id) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_TICKET_SUBCATEGORY",
      Method: "GET",
    },

    Parameters: {
      id,
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };

  const response = await axios(axiosoptions);

  return response.data;
}

export async function raiseTicket(payload) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "RAISE_TICKET",
      Method: "POST",
    },

    Parameters: {
      data: payload,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}

//
export async function listTickets() {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_TICKETS_FOR_USER",
      Method: "GET",
    },

    Parameters: {
      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}

export async function filterTicketsViaDate(startDate, endDate) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "FILTER_TICKET_VIA_DATE",
      Method: "POST",
    },

    Parameters: {
      startDate,
      endDate,

      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}
//
export async function filterTicketsViaStatusPriority(priority, status) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "FILTER_TICKET_PRIORITY_STATUS",
      Method: "POST",
    },

    Parameters: {
      priority,
      status,

      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}

export async function getTicket(ticketid) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "GET_TICKET",
      Method: "GET",
    },

    Parameters: {
      ticketid,

      data: {},
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}

export async function updateTicketService(payload) {
  let formattedPayload = {
    Dashboard_Details: {
      Dashboard_Name: "Lobby",
      Dashboard_Version: "V1",
      API_Route: "UPDATE_TICKET",
      Method: "PATCH",
    },

    Parameters: {
      data: payload,
    },
  };

  const axiosoptions = {
    url: `${API_ENDPOINT}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      session_id: getSession("session_id"),
      Authorization: `Bearer ${getSession("session_hwai")}`,
    },
    data: formattedPayload,
    withCrendentials: true,
  };
  const response = await axios(axiosoptions);

  return response.data;
}
