// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useState } from "react";
import TicketDetail from "../popups/TicketDetail";
import { RxCaretSort } from "react-icons/rx";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
const TicketTable = ({ data }) => {
  const [detailTicketMode, setdetailTicketMode] = useState(false);
  const [openTicketId, setOpenTicketId] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  // To handle sorting
  const handleSort = (column) => {
    const newSortDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortDirection(newSortDirection);
  };

  const sortedData = [...data].sort((val1forsortfunc, val2forsortfunc) => {
    if (!sortColumn) return 0;
    if (val1forsortfunc[sortColumn] < val2forsortfunc[sortColumn])
      return sortDirection === "asc" ? -1 : 1;
    if (val1forsortfunc[sortColumn] > val2forsortfunc[sortColumn])
      return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  // To open tickets detail
  function onOpenDetailMode(id) {
    setdetailTicketMode(true);
    setOpenTicketId(id);
  }

  function offUpdateTicketMode() {
    setdetailTicketMode(false);
  }

  return (
    <>
      <div className="mb-[10px]">
        {/* Table headers */}
        <div className="grid grid-cols-[1fr_1.5fr_0.8fr_0.8fr_0.8fr] px-[8px] py-[4px] Roboto border-b uppercase border-[#7D7D7D] text-zinc-500">
        <div
            className="flex flex-row cursor-pointer"
            onClick={() => handleSort("raisedDate")}
          >
            <div className="text-[11px] font-semibold leading-[18px] tracking-wide">
              Date Issued
            </div>
            <RxCaretSort />
          </div>

          <div className="text-[11px] font-semibold leading-[18px] tracking-wide ">
            Dashboard Name
          </div>
          <div
            className="flex flex-row cursor-pointer"
            onClick={() => handleSort("priority")}
          >
            <div className="text-[11px] font-semibold leading-[18px] tracking-wide">
              Priority
            </div>
            <RxCaretSort />
          </div>
          <div
            className="flex flex-row cursor-pointer"
            onClick={() => handleSort("status")}
          >
            <div className="text-[11px] font-semibold leading-[18px] tracking-wide">
              Status
            </div>
            <RxCaretSort />
          </div>
          <div className="text-[11px] font-semibold leading-[18px] tracking-wide">
            Details
          </div>
        </div>

        {/* Table data - Scrollable */}
        <div
          className="overflow-y-auto max-h-[197px] h-[197px] border-t border-gray-200 flex flex-col justify-start" // Limit to 5 items height (adjust as needed)
          style={{
            maxHeight: "197px",
            height:"197px" // Adjust height for 5 items
          }}
        >
          {sortedData.map((d) => (
            <div
              className="grid grid-cols-[1fr_1.5fr_0.8fr_0.8fr_0.8fr] px-[8px] py-[8px] Roboto border-b"
              key={d._id}
            >
              <div className="text-zinc-800 text-[13px] font-normal leading-[18px] tracking-wide">
                {d?.raisedDate?.slice(0, 10)}
              </div>
              <div
                className="text-zinc-800 text-[13px] font-normal leading-[18px] tracking-wide overflow-hidden text-ellipsis whitespace-nowrap"
                title={d?.feature} // Tooltip to show the full text on hover
              >
                {d?.feature || ""}
              </div>
              <div
                className={`${
                  d?.priority === "Low"
                    ? "bg-green-200 text-green-700"
                    : d?.priority === "Medium"
                    ? "bg-[#FFF8B5] text-yellow-700"
                    : "bg-[#FFD6DA] text-red-700"
                } uppercase w-[64px] rounded-xl flex justify-center items-center`}
              >
                <div className="text-[10px] font-semibold leading-[18px] tracking-wide">
                  {d?.priority}
                </div>
              </div>

              <div className="text-zinc-800 text-[13px] font-normal leading-[18px] tracking-wide">
                {d?.status}
              </div>
              <div
                onClick={() => onOpenDetailMode(d?._id)}
                className="flex cursor-pointer text-right text-[#7D528B] text-[13px] font-semibold leading-[18px] tracking-wide"
              >
                See Details
              </div>
            </div>
          ))}
        </div>
      </div>

      {detailTicketMode && (
        <TicketDetail onCancel={offUpdateTicketMode} ticketId={openTicketId} />
      )}
    </>
  );
};



export default memo(TicketTable);
