// All content, trademarks, and data on this document are the property of Healthworks Analytics, LLC and are protected by applicable intellectual property laws. Unauthorized use, reproduction, or distribution of this material is strictly prohibited.
import { memo, useEffect, useRef, useState } from "react";

import AuthError from "../errors/AuthError";
import SearchBar from "../bars/SearchBar";
import { Link, useParams } from "react-router-dom";
import {
  hideTour,
  setActiveTourComponent,
  setTourActivity,
  showDashboardFeedback,
  showHelpDoc,
  showTour,
} from "../../store/auth/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import OrgsDropDown from "../dropdowns/OrgsDropDown";
import { MdHelpOutline, MdOutlineChat } from "react-icons/md";

import { RiCustomerService2Line } from "react-icons/ri";
import { PiUserCircleLight } from "react-icons/pi";
import TopForkTourCard from "../tours/TopForkTourCard";
import { getHelpDocPopUpById } from "../../services/helpDocPopup.service";
import { getSession } from "../../utils/sessionstore";
import { fabClasses } from "@mui/material";
import HelpGuide from "./HelpGuide";
import { userLogsAPI } from "../../services/session.service";

const Header = ({ setShowPopup }) => {
  const { clientID } = useParams();

  const dispatch = useDispatch();

  const {
    isTourActive,
    activeTourComponent,
    activedashboard,
    userDetails,
    error,
    orgs,
    clientname,
  } = useSelector((state) => ({
    activedashboard: state.activedashboard,
    userDetails: state.userDetails,
    error: state.error,
    orgs: state.orgs,
    isTourActive: state.isTourActive,
    activeTourComponent: state.activeTourComponent,
    clientname: state.clientname,
  }));
  const [helpActive, setHelpActive] = useState(false);
  //
  const [notificationActive, setNotificationActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const sessionId = getSession("session_id");
  const token = getSession("session_hwai");
  const clientid = getSession("client_id");

  function onNextToTicket() {
    dispatch(hideTour());
    dispatch(setTourActivity(false));
    dispatch(setActiveTourComponent({}));
    // call api
  }

  const [helpObj, setHelpObj] = useState([]);
  const [open, setOpen] = useState(false);

  async function getHelpDocPopUpByIdAPI(id) {
    try {
      const response = await getHelpDocPopUpById(id);
      setHelpObj(response || []);
      setOpen(true);
      return response;
    } catch (e) {
      console.log(e);
    }
  }
  function handleHelpGuideClick() {
    if (activedashboard?.helpDoc.includes("popupId-")) {
      const popupId = activedashboard?.helpDoc.split("-")[1];
      getHelpDocPopUpByIdAPI(popupId);
    } else {
      dispatch(showHelpDoc());
    }
    // callUserLogAPIForHelpGuide();
  }
  async function callUserLogAPIForHelpGuide(startTime, endTime) {
    try {
      const logresponse = userLogsAPI(
        "USER_GUIDE",
        activedashboard?.standarddashboardId?.standardName || "",
        userDetails,
        clientname,
        activedashboard?.dashboardname || "",
        "Viewed",
        clientid,
        activedashboard?._id || "",
        startTime,
        endTime
      );
      // console.log(logresponse, "banner click");
    } catch (err) {
      console.log(err.message);
    }
  }

  async function callUserLogAPIForHelpGuideSection({
    chapter = "",
    section = "",
  }) {
    try {
      const logresponse = userLogsAPI(
        "USER_GUIDE",
        (activedashboard?.standarddashboardId?.standardName || "") +
          " - " +
          chapter +
          " - " +
          section,
        userDetails,
        clientname,
        activedashboard?.dashboardname || "",
        "Viewed",
        clientid,
        activedashboard?._id || ""
      );
      // console.log(logresponse, "banner click");
    } catch (err) {
      console.log(err.message);
    }
  }

  async function callUserLogAPIForHelpGuideChapter({ chapter = "" }) {
    try {
      const logresponse = userLogsAPI(
        "USER_GUIDE",
        (activedashboard?.standarddashboardId?.standardName || "") +
          " - " +
          chapter,
        userDetails,
        clientname,
        activedashboard?.dashboardname || "",
        "Viewed",
        clientid,
        activedashboard?._id || ""
      );
      // console.log(logresponse, "banner click");
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    // Close the context menu when a click occurs outside
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setHelpActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setShowPopup(false);
  }, [window.location.pathname]);

  const startTimeRef = useRef(null);

  // Session start and end handler
  const handleSessionStart = () => {
    startTimeRef.current = new Date();
  };

  const handleSessionEnd = () => {
    if (!startTimeRef.current || activedashboard?.dashboardname === undefined) {
      // || !open
      return; // Prevent logging if startTime  isn't set or dashboard name is not there
    }
    const endTime = new Date();
    callUserLogAPIForHelpGuide(startTimeRef.current, endTime);
    startTimeRef.current = null;
  };

  const handleBeforeUnload = (event) => {
    handleSessionEnd(); // End session before page unload
    event.preventDefault();
    event.returnValue = ""; // Trigger confirmation dialog
  };

  useEffect(() => {
    if (open) handleSessionStart();

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      handleSessionEnd(); // End session on component unmount
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [open]);

  const menuRef = useRef(null);
  //again check 14
  return (
    <>
      <div className="absolute z-10 h-14 bg-headerbg pt-4 left-[45px] top-0 right-0 items-center flex justify-center border-b border-[#DDDDDC]">
        <HelpGuide
          sectionLog={callUserLogAPIForHelpGuideSection}
          chapterLog={callUserLogAPIForHelpGuideChapter}
          open={open}
          setOpen={setOpen}
          data={helpObj}
          setData={setHelpObj}
        />
        <div className="justify-between flex absolute left-[45px] right-[45px] top-0 items-center mt-1  mx-auto">
          {/* <img
            alt="HWAI"
            src={logo}
            className="h-8  cursor-pointer"
            onClick={() => navigate("/")}
          /> */}
          <div className="flex gap-3 justify-start items-center">
            <div
              onClick={() => {
                if (
                  userDetails?.permissions === "HWAI_ADMIN" &&
                  clientID !== undefined
                ) {
                  navigate(`/${clientID}`);
                } else {
                  navigate("/");
                }
              }}
              className="cursor-pointer w-[186px]"
            >
              <img
                alt="HWAI"
                src={
                  "https://lobbystore.blob.core.windows.net/images/HWAI_Logo_Full.svg?v=1"
                }
                className="cursor-pointer select-none pointer-events-none"
              />
            </div>
            {orgs?.length > 0 && userDetails?.permissions === "HWAI_ADMIN" && (
              <div className="">
                <OrgsDropDown />
              </div>
            )}
          </div>

          <div className="flex gap-4 justify-end items-center">
            <div className="mb-2">
              <SearchBar
                notificationActive={notificationActive}
                setNotificationActive={setNotificationActive}
              />
            </div>

            <div className="flex flex-row items-center gap-3">
              {pathParts.includes("dashboard") && (
                <div
                  className="cursor-pointer flex items-center pt-1 w-6"
                  onClick={() => dispatch(showDashboardFeedback())}
                >
                  <div className="text-[#5C5C5C] hover:text-[#333333] transition-colors">
                    <MdOutlineChat size={25} />
                  </div>
                </div>
              )}
              <div className="relative flex items-center">
                {isTourActive && activeTourComponent?.name === "Ticket" && (
                  <div className="z-10 absolute top-0 left-0 -translate-x-[251px] translate-y-[38px]">
                    <TopForkTourCard
                      // onNext={onNextToTicket}
                      number={6}
                      description={
                        "Need assistance or want to report an issue? Raise a ticket easily to get help from our support team. Your concerns are our top priority."
                      }
                      title={"Raise ticket"}
                      icon={
                        "https://lobbystore.blob.core.windows.net/tour/ri_customer-service-2-line.svg"
                      }
                    />
                  </div>
                )}
                <button
                  className={` flex items-center ${
                    window.location.href.includes("ticket")
                      ? "opacity-50 cursor-default"
                      : ""
                  }`}
                  onClick={() => {
                    console.log("check new changes - TICKET");
                    if (!window.location.href.includes("ticket")) {
                      setShowPopup(true);
                    }
                  }}
                  disabled={window.location.href.includes("ticket")}
                >
                  <RiCustomerService2Line
                    className="fill-[#5C5C5C] hover:fill-[#333333]"
                    size={24}
                  />
                </button>
                {/* {showPopup && (
                  <CreateTicketPopup
                    searchedResult={searchedResult}
                    setSearchedResult={setSearchedResult}
                    closePopup={() => setShowPopup(false)}
                  />
                )} */}
              </div>
              {/* help option start */}
              <div
                onClick={() => {
                  setHelpActive(!helpActive);
                  // console.log(pathParts);
                }}
                className="cursor-pointer flex items-center pt-[2px]"
              >
                <div className="relative">
                  <MdHelpOutline
                    className="fill-[#5C5C5C] hover:fill-[#333333]"
                    size={24}
                  />
                  {helpActive && (
                    <div className="absolute top-0 right-0 border-none translate-y-[30px] w-max h-max">
                      <div
                        ref={menuRef}
                        className="w-max h-max p-1 bg-[#ffffff] border border-[#494949] shadow-md flex flex-col"
                      >
                        <button
                          onClick={() => {
                            // dispatch(
                            //   setActiveTourComponent({ name: "Nav", number: 1 })
                            // );
                            // dispatch(setTourActivity(true));
                            // dispatch(hideTour());
                            dispatch(showTour(true));
                            if (pathParts[1] && pathParts[1] !== "") {
                              if (
                                userDetails?.permissions === "HWAI_ADMIN" &&
                                clientID !== undefined
                              ) {
                                navigate(`/${clientID}`);
                              } else {
                                navigate("/");
                              }
                            }
                          }}
                          className={`flex flex-row gap-1 px-1 py-[1px]  items-center justify-end ${
                            !(
                              pathParts[1] == "" ||
                              (pathParts.length == 2 &&
                                pathParts[1] == clientID)
                            )
                              ? "opacity-50"
                              : "hover:bg-[#d3d3d3]"
                          }`}
                          disabled={
                            !(
                              pathParts[1] == "" ||
                              (pathParts.length == 2 &&
                                pathParts[1] == clientID)
                            )
                          }
                          // className="flex flex-row gap-1 px-1 py-[1px]  hover:bg-[#d3d3d3] items-center justify-start"
                        >
                          <img
                            alt="tour"
                            src="https://lobbystore.blob.core.windows.net/images/take-tour-icon.svg?v=1"
                          />
                          <div className="text-sm text-[#333333] Roboto text-left">
                            Take a tour
                          </div>
                        </button>
                        <button
                          onClick={handleHelpGuideClick}
                          className={`flex flex-row gap-1 px-1 py-[1px] items-center justify-end ${
                            !(
                              pathParts.includes("dashboard") &&
                              activedashboard?.helpDoc &&
                              activedashboard?.helpDoc?.length > 0
                            )
                              ? "opacity-50"
                              : "hover:bg-[#d3d3d3]"
                          }`}
                          disabled={
                            !(
                              pathParts.includes("dashboard") &&
                              activedashboard?.helpDoc &&
                              activedashboard?.helpDoc?.length > 0
                            )
                          }
                        >
                          <img
                            alt="tour"
                            src="https://lobbystore.blob.core.windows.net/images/help-guide.svg?v=1"
                          />
                          <div className="text-sm text-[#333333] Roboto text-left">
                            Help Guide
                          </div>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* help option ends */}
              <div
                onClick={() => {
                  if (
                    clientID !== undefined &&
                    orgs.map((org) => org._id).includes(clientID) &&
                    userDetails?.permissions === "HWAI_ADMIN"
                  ) {
                    navigate(`/${clientID}/profile`);
                  } else {
                    navigate("/profile");
                  }
                }}
              >
                <div className="Roboto gap-2 flex text-[14px] cursor-pointer border rounded-[100px] p-[1px] pr-1 border-[#DDDDDC]">
                  <PiUserCircleLight className=" text-2xl" />
                  {userDetails?.firstName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {error && error.length > 0 && <AuthError />}
    </>
  );
};

export default memo(Header);
